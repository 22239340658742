import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { LandingPage } from '@flexera/shell.org-landing';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import {
	stratusDashboard,
	registerBucket,
	importSbom,
	jobs,
	bucketsGrid,
	manageSbomWrapper
} from './StratusApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	// TODO: fill out routes with actual paths and components
	useEffect(() => {
		return addRoutes([
			{
				id: 'stratus.bootstrap/home',
				parentId: OrgsRouteId,
				path: '/landing',
				exact: false,
				component: LandingPage
			},
			// {
			// 	id: 'stratus.bootstrap/dashboard',
			// 	parentId: OrgsRouteId,
			// 	path: '/sbom-insights/dashboard',
			// 	exact: false,
			// 	component: stratusDashboard
			// },
			{
				id: 'stratus.bootstrap/createBucket',
				parentId: OrgsRouteId,
				path: '/sbom-insights/createBucket',
				exact: false,
				component: registerBucket
			},
			{
				id: 'stratus.bootstrap/manage-buckets',
				parentId: OrgsRouteId,
				path: '/sbom-insights/manage-buckets',
				exact: false,
				component: bucketsGrid
			},
			{
				id: 'stratus.bootstrap/manage-sbom-parts',
				parentId: OrgsRouteId,
				path: '/sbom-insights/manage-sbom-parts',
				exact: false,
				component: manageSbomWrapper
			},
			{
				id: 'stratus.bootstrap/importSbom',
				parentId: OrgsRouteId,
				path: '/sbom-insights/importSbom',
				exact: false,
				component: importSbom
			},
			{
				id: 'stratus.bootstrap/jobs',
				parentId: OrgsRouteId,
				path: '/sbom-insights/jobs',
				exact: false,
				component: jobs
			}
		]);
	}, []);
}
