/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useContext, FC } from 'react';
import {
	useClearCurrentOrg,
	useCurrentOrg,
	useOrgs,
	useOrgId
} from '@flexera/shell.orgs';
import { HostIndicator as FNMSHostIndicator } from '@flexera/fnms.core/src/HostIndicator';
import { history } from '@flexera/lib.router-history';
import { useUserProfile, useLogout } from '@flexera/auth.base';
import { Org } from '@flexera/services.grs';
import {
	HeaderOrgSwitcher,
	HeaderProfile,
	HeaderProfileProps
	// Icon,
	// IconMdNotificationsDeactivated
} from '@flexera/ui.component-library';
import { t } from 'ttag';
import {
	HeaderStyled,
	HeaderLeft,
	StyledHeaderRight,
	HeaderItem
} from '../styles/HeaderWrapper';
import { Breadcrumbs } from './HeaderBreadcrumbs';
import { BranchIndicator } from './BranchIndicator';
import { HelpNavLink } from './HelpNavLink';
import { AccountIcon } from '../assets';

export const Header: FC = () => {
	const user = useUserProfile();
	const logout = useLogout();
	const organisations = useOrgs();
	const currentOrg = useCurrentOrg();
	const clearOrg = useClearCurrentOrg();
	const orgId = useOrgId();
	const [orgsWithLabel, setOrgsWithLabel] = useState([]);

	useEffect(() => {
		if (organisations && Object.keys(currentOrg).length > 2) {
			setOrgsWithLabel(
				organisations
					.map((org: Org) => ({
						...org,
						hasDivider: false,
						isHeading: false,
						isSelected: currentOrg.id === org.id,
						label: org.name,
						onClick: () => {
							if (org.id === currentOrg.id) return;
							clearOrg();
							history.push(`/orgs/${org.id}`);

							// Push Google Tag Manager event
							(window as any).dataLayer = (window as any).dataLayer || [];
							(window as any).dataLayer.push({ event: 'org_changed' });
						}
					}))
					.sort((orgOne, orgTwo) => {
						return orgOne.label.localeCompare(orgTwo.label);
					})
			);
		}
	}, [organisations, currentOrg]);

	const headerProfileMenuItems: HeaderProfileProps['menuItems'] = [
		{
			href: 'https://www.revenera.com/legal/privacy-policy.html',
			target: '_blank',
			text: t`Privacy Policy`
		},
		{
			onClick: (): void => {
				history.push('/settings/invitations');
			},
			text: t`User Settings`
		},
		{
			isLogout: true,
			onClick: () => logout(),
			text: t`Log out`
		}
	];

	return (
		<HeaderStyled currentPath={location.pathname}>
			<HeaderLeft>
				<Breadcrumbs />
			</HeaderLeft>
			<StyledHeaderRight>
				<HeaderItem currentPath={location.pathname}>
					<span
						style={{ fontSize: '0.875rem' }}
					>{t`Revenera SCA - SBOM Insights 2023.04.1`}</span>
				</HeaderItem>
				{orgId && orgsWithLabel && orgsWithLabel.length > 0 && (
					<HeaderItem currentPath={location.pathname}>
						<HeaderOrgSwitcher
							id={'header-org-switcher'}
							title={t`Organizations`}
							label={t`Select an Organization`}
							headingLabel={currentOrg ? currentOrg.name : ''}
							orgs={orgsWithLabel}
						/>
					</HeaderItem>
				)}
				<FNMSHostIndicator />
				<BranchIndicator />
				<HeaderItem currentPath={location.pathname}>
					<HeaderProfile
						id={'header-profile'}
						menuItems={headerProfileMenuItems}
						title={t`Profile details`}
						name={user && `${user.firstName} ${user.lastName}`}
						ProfilePicture={AccountIcon}
					/>
				</HeaderItem>

				{/* To be added at a later stage */}
				{/* <HeaderItem tabIndex={0}>
					<Icon
						src={IconMdNotificationsDeactivated}
						opacity={0.5}
						title={'Coming Soon'}
					/>
				</HeaderItem> */}
				<HeaderItem currentPath={location.pathname}>
					<HelpNavLink />
				</HeaderItem>
			</StyledHeaderRight>
		</HeaderStyled>
	);
};
