/* eslint-disable consistent-return */
import { useAuthenticated } from '@flexera/auth.base';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { STRATUS } from '@flexera/shell.base-nav-tree';
import { useEffect } from 'react';
import { t } from 'ttag';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();
	const capabilityPath = `/orgs/${orgId}/sbom-insights`;
	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.hasSbom,
		Permissions.canCreateBucket,
		Permissions.canSeeBucketIndex,
		Permissions.canCreateSbomPart,
		Permissions.canSeeSbomPartIndex,
		Permissions.canImportSbom,
		Permissions.canSeeSbomJobIndex
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasSbom)) return;

		// TODO: set a name, icon, and priority for the capability in nav!
		return addItems(
			[
				{
					id: ids.Home,
					parentId: STRATUS,
					label: t`Home`,
					path: `/orgs/${orgId}/landing`,
					urlMatch: /^\/orgs\/\d+\/landing(\/|$|\?)/
				},
				// {
				// 	id: ids.DASHBOARD,
				// 	parentId: STRATUS,
				// 	label: t`Dashboard`,
				// 	path: `${capabilityPath}/dashboard`,
				// 	urlMatch: /^\/orgs\/\d+\/sbom-insights\/dashboard(\/|$|\?)/
				// },
				perms.get(Permissions.canCreateBucket) && {
					id: ids.REGISTERBUCKET,
					parentId: STRATUS,
					label: t`Create Bucket`,
					path: `${capabilityPath}/createBucket`,
					urlMatch: /^\/orgs\/\d+\/sbom-insights\/createBucket(\/|$|\?)/,
					helpHref: `${helpDocBaseUrl}/tile_buckets.htm`
				},
				perms.get(Permissions.canSeeBucketIndex) && {
					id: ids.MANAGEBUCKETS,
					parentId: STRATUS,
					label: t`Manage Buckets`,
					path: `${capabilityPath}/manage-buckets`,
					urlMatch: /^\/orgs\/\d+\/sbom-insights\/manage-buckets(\/|$|\?)/,
					helpHref: `${helpDocBaseUrl}/tile_buckets.htm`
				},
				perms.get(Permissions.canSeeSbomPartIndex) && {
					id: ids.MANAGESBOM,
					parentId: STRATUS,
					label: t`Manage SBOM Parts`,
					path: `${capabilityPath}/manage-sbom-parts`,
					urlMatch: /^\/orgs\/\d+\/sbom-insights\/manage-sbom-parts(\/|$|\?)/,
					helpHref: `${helpDocBaseUrl}/tile_view.htm`
				},
				perms.get(Permissions.canImportSbom) && {
					id: ids.IMPORTSBOM,
					parentId: STRATUS,
					label: t`Import SBOM`,
					path: `${capabilityPath}/importSbom`,
					urlMatch: /^\/orgs\/\d+\/sbom-insights\/importSbom(\/|$|\?)/,
					helpHref: `${helpDocBaseUrl}/tile_import.htm`
				},
				perms.get(Permissions.canSeeSbomJobIndex) && {
					id: ids.JOBS,
					parentId: STRATUS,
					label: t`Jobs`,
					path: `${capabilityPath}/jobs`,
					urlMatch: /^\/orgs\/\d+\/sbom-insights\/jobs(\/|$|\?)/,
					helpHref: `${helpDocBaseUrl}/Viewing_Your_Jobs.htm`
				}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated, loadingPerms, perms]);
}
